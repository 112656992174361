<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <div class="card">
        <Panel header="Relatórios" class="mt-3">
          <form @submit.prevent="validate()">
            <div class="p-fluid formgrid grid">
              <div class="field col-6 md:col-6">
                <label for="tipo">Tipo</label>
                <Dropdown
                  id="tipo"
                  v-model="v$.relatorio.tipo.$model"
                  :class="{
                    'p-invalid': submitted && v$.relatorio.tipo.$invalid,
                  }"
                  :options="tipos"
                  :filter="true"
                  filterPlaceholder="Procure pelo tipo"
                  :emptyFilterMessage="'Nenhuma tipo encontrado'"
                  placeholder="Selecione um tipo"
                  :disabled="desativarCampo()"
                  @change="carregarNomesRelatoriosPorTipoSelecionado()">
                </Dropdown>
                <div
                  v-if="submitted && v$.relatorio.tipo.required.$invalid"
                  class="p-error">
                  O campo tipo é obrigatório.
                </div>
              </div>
              <div class="field col-6 md:col-6">
                <label for="nome">Relatório</label>
                <Dropdown
                  id="nome"
                  v-model="v$.relatorioSelecionado.$model"
                  data-key="nome"
                  :class="{
                    'p-invalid': submitted && v$.relatorioSelecionado.$invalid,
                  }"
                  :options="relatorios"
                  optionLabel="nome"
                  :filter="true"
                  filterPlaceholder="Procure pelo nome do relatorio"
                  :emptyFilterMessage="'Nenhuma relatorio encontrado'"
                  placeholder="Selecione um relatório"
                  :disabled="desativarCampo()"
                  @change="carregarFiltrosRelatorio()">
                </Dropdown>
                <div
                  v-if="submitted && v$.relatorioSelecionado.required.$invalid"
                  class="p-error">
                  O campo relatório é obrigatório.
                </div>
              </div>
            </div>
            <component
              :is="componente"
              v-if="componente"
              ref="filtroComponente"
              :submitted="submitted"
              :modulo="modulo"
              @parametros="adicionarParametros"></component>
            <Button
              type="submit"
              autofocus
              :disabled="loadingRelatorioPDF"
              class="mt-2 mr-2 p-button-danger"
              @click="gerarRelatorioPdf">
              <span
                v-if="loadingRelatorioPDF"
                class="pi pi-spin pi-spinner"></span>
              <span v-if="!loadingRelatorioPDF" class="ml-2">Gerar PDF</span>
              <span v-if="loadingRelatorioPDF" class="ml-2">Aguarde</span>
            </Button>
            <Button
              type="submit"
              autofocus
              :disabled="loadingRelatorioExcel"
              class="mt-2 mr-2 p-button-success"
              @click="gerarRelatorioExcel">
              <span
                v-if="loadingRelatorioExcel"
                class="pi pi-spin pi-spinner"></span>
              <span v-if="!loadingRelatorioExcel" class="ml-2"
                >Gerar Excel</span
              >
              <span v-if="loadingRelatorioExcel" class="ml-2">Aguarde</span>
            </Button>
          </form>
        </Panel>
      </div>
    </div>
    <TemplateRelatorio v-if="gerarRelatorio" :key="childKey" />
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Filtro01 from './filtros/Filtro01.vue'
import Filtro02 from './filtros/Filtro02.vue'
import Filtro03 from './filtros/Filtro03.vue'
import FiltroTaxaAssistencial from './filtros/FiltroTaxaAssistencial.vue'
import Filtro05 from './filtros/Filtro05.vue'
import FiltroRelatorioCobranca from './filtros/FiltroRelatorioCobranca.vue'
import ServidorService from '@/service/ServidorService.js'
import TemplateRelatorio from '@/components/shared/relatorio/TemplateRelatorio.vue'
import Relatorio from '@/domain/Relatorio.js'
import RelatorioService from '@/service/RelatorioService.js'
import { storeRelatorio } from '@/stores/relatorio'

export default {
  name: 'LayoutRelatorio',

  components: {
    Filtro01,
    Filtro02,
    Filtro03,
    FiltroTaxaAssistencial,
    Filtro05,
    TemplateRelatorio,
    FiltroRelatorioCobranca,
  },

  setup() {
    const relatorioStore = storeRelatorio()
    return { v$: UseVuelidate(), relatorioStore }
  },

  data() {
    return {
      relatorio: new Relatorio(),
      relatorioSelecionado: null,
      listaRelatorios: null,
      tipos: [],
      relatorios: [],
      submitted: false,
      loadingRelatorioPDF: false,
      loadingRelatorioExcel: false,
      componente: null,
      tipoGeradorDeRelatorio: null,
      gerarRelatorio: false,
      modulo: null,
      childKey: 0,
    }
  },

  validations() {
    return {
      relatorio: {
        tipo: { required },
      },
      relatorioSelecionado: { required },
    }
  },

  created() {
    this.relatorioService = new RelatorioService(this.$http)
    this.ServidorService = new ServidorService(this.$http)
    this.carregarModulo()
  },

  methods: {
    desativarCampo() {
      const pagina = window.location.hostname.split('.')
      let subdomain

      if (pagina[0] === 'dev') {
        subdomain = pagina[1]
      } else {
        subdomain = pagina[0]
      }
      if (
        (this.$auth.hasRoleConsignatariaUser() ||
          this.$auth.hasRoleConsignatariaAdmin()) &&
        subdomain === 'consignataria'
      )
        return !JSON.parse(localStorage.getItem('consignatariaSelecionada'))
      return JSON.parse(localStorage.getItem('consignatariaSelecionada'))
    },

    carregarTipos() {
      this.listaRelatorios.relatoriosPorTipo.forEach((value, key) => {
        this.tipos.push(key)
      })

      //adicionar valor padrão na listagem de tipo
      this.relatorio.tipo = this.listaRelatorios.relatoriosPorTipo
        .keys()
        .next().value

      this.carregarNomesRelatoriosPorTipoSelecionado()
    },

    carregarNomesRelatoriosPorTipoSelecionado() {
      this.relatorios = this.listaRelatorios.relatoriosPorTipo.get(
        this.relatorio.tipo,
      )

      //Verificando permissões de visualização do relatório
      this.relatorios = this.relatorios
        .map((r) => {
          if (
            r.permissoes.some((permissao) =>
              this.$auth.roles.includes(permissao),
            )
          ) {
            return r // mantém o objeto atual no novo array
          }
          return null // descarta o objeto que não atende à condição
        })
        .filter((r) => r !== null)

      this.componente = ''
    },

    carregarFiltrosRelatorio() {
      this.submitted = false
      this.relatorio.nome = this.relatorioSelecionado.nomeRelatorio
      this.componente = this.relatorioSelecionado.componente
      this.tipoGeradorDeRelatorio =
        this.relatorioSelecionado.tipoGeradorDeRelatorio
    },

    adicionarParametros(p) {
      this.relatorio.parametros = p
      //console.log(this.relatorio)
    },

    gerarRelatorioPdf() {
      //console.log(this.relatorio)
      if (this.validate()) {
        this.loadingRelatorioPDF = true
        if (this.tipoGeradorDeRelatorio == 'jasper') {
          this.relatorioService.gerarRelatorioViaJasper(this.relatorio).then(
            (res) => {
              this.downloadRelatorio('PDF', this.relatorio.nome, res)
              setTimeout(this.limpar(), 5000)
            },
            (err) => {
              this.exibeToast('error', err.response.data.message)
              this.limpar()
            },
          )
        } else if (this.tipoGeradorDeRelatorio == 'html') {
          this.relatorioService
            .gerarRelatorioViaHtml(this.relatorio, this.modulo)
            .then(
              (res) => {
                this.gerarRelatorioPorTemplateHtml(
                  'PDF',
                  this.relatorio.nome,
                  res,
                  this.relatorio.parametros.cpf,
                )
                setTimeout(this.limpar(), 5000)
              },
              (err) => {
                this.exibeToast('error', err.response.data.message)
                this.limpar()
              },
            )
        }
      }
    },

    gerarRelatorioExcel() {
      if (this.validate()) {
        this.loadingRelatorioExcel = true
        if (this.tipoGeradorDeRelatorio == 'jasper') {
          this.relatorio.contentType = 'EXCEL'
          this.relatorioService.gerarRelatorioViaJasper(this.relatorio).then(
            (res) => {
              this.downloadRelatorio('EXCEL', this.relatorio.nome, res)
              setTimeout(this.limpar(), 5000)
            },
            (err) => {
              this.exibeToast('error', err.response.data.message)
              this.limpar()
            },
          )
        } else if (this.tipoGeradorDeRelatorio == 'html') {
          this.relatorioService
            .gerarRelatorioViaHtml(this.relatorio, this.modulo)
            .then(
              (res) => {
                this.gerarRelatorioPorTemplateHtml(
                  'EXCEL',
                  this.relatorio.nome,
                  res,
                  this.relatorio.parametros.cpf,
                )
                setTimeout(this.limpar(), 5000)
              },
              (err) => {
                this.exibeToast('error', err.response.data.message)
                this.limpar()
              },
            )
        }
      }
    },

    validate() {
      this.submitted = true
      this.v$.relatorio.$touch()
      this.v$.relatorioSelecionado.$touch()

      if (
        this.v$.relatorio.tipo.$invalid ||
        this.v$.relatorioSelecionado.$invalid
      ) {
        return false
      } else {
        if (this.$refs.filtroComponente) {
          return this.$refs.filtroComponente.validate() ? false : true
        }

        return true
      }
    },

    //Métodos referentes para gerar pdf e excel via stream gerado pelo template jasper
    downloadRelatorio(contentyType, nomeRelatorio, response) {
      if ('PDF' === contentyType) {
        const blob = new Blob([response], {
          type: 'application/pdf',
        })

        saveAs(blob, nomeRelatorio + '.pdf')
      } else if ('EXCEL' === contentyType) {
        const blob = new Blob([response], {
          type: 'text/csv',
        })

        saveAs(blob, nomeRelatorio + '.xls')
      }
    },

    //Método referente para gerar pdf e excel via template html
    gerarRelatorioPorTemplateHtml(
      contentType,
      nomeRelatorio,
      response,
      cpfServidor,
    ) {
      if (this.notEmpty(response)) {
        this.relatorioStore.contentType = contentType
        this.relatorioStore.nomeRelatorio = nomeRelatorio
        this.relatorioStore.response = response
        this.relatorioStore.cpf = cpfServidor
        this.gerarRelatorio = true
      } else {
        this.gerarRelatorio = false
        this.exibeToast('error', 'Os filtros informados não possui dados.')
      }
    },

    notEmpty(valor) {
      if (Array.isArray(valor)) {
        return valor !== null && valor !== undefined && valor?.length > 0
          ? true
          : false
      } else {
        return Object.keys(valor).length !== 0 ? true : false
      }
    },

    limpar() {
      this.$refs.filtroComponente.limpar()
      this.relatorio.parametros = new Map()
      this.submitted = false
      this.loadingRelatorioPDF = false
      this.loadingRelatorioExcel = false
      this.reloadChild()
    },

    reloadChild() {
      // Update the key to trigger a reload of the child component
      this.childKey++
    },

    exibeToast(tipo, msg) {
      if (tipo === 'success') {
        this.$toast.add({
          severity: 'success',
          summary: 'Relatório solicitado.',
          life: 10000,
        })
      } else if (tipo === 'error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      }
    },

    verificarModulo() {
      const host = window.location.host
      const parts = host.split('.')
      const subdomain =
        parts[0] === 'dev' || parts[0] === 'teste' ? parts[1] : parts[0]

      const subdomainToModuloMap = {
        servidor: 'servidor',
        gerencia: 'gestao',
        gestao: 'gestao',
        consignataria: 'consignataria',
      }

      const modulo = subdomainToModuloMap[subdomain] || 'servidor'
      return modulo
    },

    carregarModulo() {
      this.modulo = this.verificarModulo()
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  margin: 0 2px;
}
@media print {
  ::v-deep .my-component {
    color: red !important;
    font-size: 16pt !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: green !important;
  }
}
</style>
